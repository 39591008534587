interface Config {
    apiUrl: string | undefined;
}


const config: Config = {
  apiUrl: process.env.REACT_APP_API_URL,

};

export default config;
