import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Checkbox, Icon, Button } from 'antd';
import { RouteProps, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useMst } from '../store/RootStore';

const Wrapper = styled.div`
  /* margin-top: 30px; */
  min-height: calc(100vh - 230px);

  .btn-submit {
    margin-top: 20px;
    font-weight: bold;
  }

  .consent-wrapper {
    .terms {
      max-height: 300px;
      overflow-y: scroll;

      background: #eee;
      border-radius: 5px;
      padding: 20px;
    }

    .action {
      margin-top: 20px;
    }
  }

  .accounts {
  }

  .accounts-select {
    width: 350px;
    font-size: 20px;

    .account {
      padding: 0px !important;
      display: flex;
    }
    .name {
      margin-right: 20px;
    }
    .number {
      font-weight: bold;
    }

    .ant-select-selection--single {
      position: relative;
      height: 50px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid #ccc;
    }
  }

  .error-message {
    display: block;
    font-weight: bold;
    color: #9c1e25;
    margin-top: 5px;
  }
`;

const styles = {
  accountsSelect: {
    border: '1px solid #ccc',
    height: '100px',
  },

  account: {
    display: 'flex',
    padding: '15px',
    fontSize: '18px',
    justifyContent: 'space-between',
    borderBottom: '1px solid #eee',
  },
};

type FormData = {
  agreement: boolean;
  account: string;
};

const schema = yup.object().shape({
  agreement: yup.bool().oneOf([true], 'You must agree to continue'),
});

const TermsAndCondition: React.FC<RouteProps> = () => {
  const store = useMst();
  const history = useHistory();

  useEffect(() => {
    store.getTermsAndConditions().then(response => {
      store.termsAndConditions.save(response.data);
    });

    store.getProfile(store.id).then(response => {
      const profile = response.data;
      store.populateStoreFromBPIProfile(profile);
    });
  }, []);

  const { errors, handleSubmit, control } = useForm<FormData>({
    validationSchema: schema,
  });

  const onSubmit = async (data: FormData) => {
    await store.startRegistration();
    history.push('/application_details');
  };

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className="consent-wrapper">
          <iframe
            src="https://bpitrade.s3.ap-southeast-1.amazonaws.com/BPI+Trade+Terms+and+Conditions+(Retail).pdf"
            frameBorder="0"
            width="100%"
            height="400px"
          ></iframe>
          <div className="action">
            <Controller
              name="agreement"
              as={<Checkbox>I have read, fully understood and agree</Checkbox>}
              control={control}
              onChange={(data): void => {
                return data[0].target.checked;
              }}
              defaultValue={false}
            />
            {errors['agreement'] ? <span className="error-message">{errors['agreement'].message}</span> : null}
          </div>
        </section>
        <Button size="large" className="btn-primary btn-submit" type="primary" htmlType="submit">
          Continue
          <Icon type="caret-right" theme="filled" />
        </Button>
      </form>
    </Wrapper>
  );
};

export default TermsAndCondition;
