import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Icon, Divider, Button } from 'antd';
import PropTypes from 'prop-types';
import { RouteProps, useHistory } from 'react-router-dom';
import Accountholder from './Accountholder';
import { useMst } from '../../store/RootStore';

const Wrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 80px;

  .page-title {
    margin-bottom: 30px;
  }

  .summary-wrapper {
    margin-bottom: 50px;
  }
`;

const ReviewApplication: React.FC<RouteProps> = () => {
  const history = useHistory();
  const store = useMst();

  const onContinue = (): void => {
    history.push('/trade_account_details');
  };

  const onPrevious = (): void => {
    history.push('/accountholder_details');
  };

  useEffect(() => {
    store.ui.setStep(2);
  }, []);

  return (
    <Wrapper>
      <h2 className="page-title">Review Accountholder details</h2>
      <p>You have entered the following information. Kindly review and ensure all details are complete and correct.</p>
      <Divider />
      <section className="summary-wrapper">
        {store.accountholders.map((accountholder, index) => (
          <Accountholder key={index} accountholder={accountholder} primary={index === 0} />
        ))}
      </section>
      <Divider />
      <Button onClick={onPrevious} className="btn-primary btn-submit mt-3 mr-3" size="large" type="default">
        <Icon type="caret-left" theme="filled" />
        Go back
      </Button>
      <Button onClick={onContinue} className="btn-primary btn-submit mt-3" size="large" type="primary">
        All details are correct, continue
        <Icon type="caret-right" theme="filled" />
      </Button>
    </Wrapper>
  );
};

export default ReviewApplication;
