import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { observer } from 'mobx-react';
import { Icon, Select, Button } from 'antd';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { RouteProps, useHistory } from 'react-router-dom';
import { schema } from '@project/common';

import { FormRow, FormSection, RequiredFlag } from '../components';
import { useMst } from '../store/RootStore';
import { ApplicationDetailsType } from '../store/ApplicationDetails';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 80px;
  min-height: calc(100vh - 250px);

  .btn-submit {
    width: 250px;
    height: 40px;
    margin-top: 30px;
    font-size: 20px;
    font-weight: bold;
  }

  .input {
    color: #333;

    &::placeholder {
      color: #888;
    }
  }

  .input-sm {
    width: 80px;
  }

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

const Main = styled.div`
  display: flex;
  /* align-items: center; */
  margin: 0px auto;

  > form {
    margin: 0px auto;
  }

  .main-form-section {
    width: 350px;

    @media (max-width: 320px) {
      width: 280px;
    }
  }
`;

type FormData = {
  currency: string;
  ownership: string;
  accountHolders?: number;
  beneficiaries?: number;
};

const ApplicationDetails: React.FC<RouteProps> = observer(() => {
  const history = useHistory();

  const store = useMst();
  useEffect(() => {
    store.ui.setStep(2);
  }, []);

  const [tmpOwnership, setTmpOwnership] = useState('');
  const { errors, handleSubmit, control } = useForm<FormData>({
    validationSchema: schema.applicationDetailsSchema,
  });

  const onSubmit = (data: FormData) => {
    store.applicationDetails.save(data as ApplicationDetailsType);

    if (data.ownership === 'joint') {
      if (data.accountHolders) {
        store.setAccountholders(data.accountHolders);
      }
    } else if (data.ownership === 'in_trust_for') {
      store.applicationDetails.setBeneficiaries(1);
      store.setAccountholders(2);
    } else {
      store.applicationDetails.setBeneficiaries(0);
      store.setAccountholders(1);
    }
    history.push('/accountholder_details');
  };

  // console.log(errors);

  return (
    <Wrapper>
      <Main>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormSection id="personal-section" className="main-form-section">
            <p className="title">Application details</p>
            <span className="description">
              All fields with <RequiredFlag /> are mandatory
            </span>
            <FormRow marginBottom="3px">
              <label className="form-title">
                Currency
                <RequiredFlag />
              </label>
              <div className={errors['currency'] ? 'controls has-error' : 'controls'}>
                <Controller
                  name="currency"
                  as={
                    <Select placeholder="Select currency">
                      <Select.Option value="PHP">PESO</Select.Option>
                      <Select.Option value="USD">DOLLAR</Select.Option>
                    </Select>
                  }
                  control={control}
                />
                {errors['currency'] ? <span className="error-message">{errors['currency'].message}</span> : null}
              </div>
            </FormRow>
            <FormRow marginBottom="3px">
              <label className="form-title">
                Ownership
                <RequiredFlag />
              </label>
              <div className={errors['ownership'] ? 'controls has-error' : 'controls'}>
                <Controller
                  name="ownership"
                  as={
                    <Select placeholder="Select ownership">
                      <Select.Option value="individual">Individual</Select.Option>
                      <Select.Option value="joint">Joint</Select.Option>
                      <Select.Option value="in_trust_for">In Trust For</Select.Option>
                    </Select>
                  }
                  onChange={data => {
                    setTmpOwnership(data[0]);

                    return data[0];
                  }}
                  control={control}
                />
              </div>
              {errors['ownership'] ? <span className="error-message">{errors['ownership'].message}</span> : null}
            </FormRow>
            {tmpOwnership === 'joint' ? (
              <FormRow marginBottom="3px">
                <label className="form-title">
                  Number of account holders
                  <RequiredFlag />
                </label>
                <div className={errors['accountHolders'] ? 'controls has-error' : 'controls'}>
                  <Controller
                    name="accountHolders"
                    as={
                      <Select placeholder="Select number of account holders">
                        <Select.Option value="2">2</Select.Option>
                        {/* <Select.Option value="3">3</Select.Option>
                        <Select.Option value="4">4</Select.Option> */}
                      </Select>
                    }
                    control={control}
                  />
                </div>
                {errors['accountHolders'] ? (
                  <span className="error-message">{errors['accountHolders'].message}</span>
                ) : null}
              </FormRow>
            ) : null}
            <FormRow style={{ marginTop: 30 }}>
              <Button size="large" htmlType="submit" type="primary">
                Continue
                <Icon type="caret-right" theme="filled" />
              </Button>
            </FormRow>
          </FormSection>
        </form>
      </Main>
    </Wrapper>
  );
});

export default ApplicationDetails;
