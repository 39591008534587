import React from 'react';
import { observer } from 'mobx-react';
import countriesList from 'country-list';
import { Input, Radio, Checkbox, Select } from 'antd';
import PropTypes from 'prop-types';

import { AccountholderType } from '../../store/Accountholder';
import { FormSection, FormRow, RequiredFlag } from '../../components';

interface ComponentProps {
  errors: any;
  accountholder: AccountholderType;
  validateInput: (path: string) => void;
}

const FATCA: React.FC<ComponentProps> = observer(({ errors, accountholder, validateInput }) => {
  return (
    <FormSection id="fatca-section">
      {' '}
      <p className="title">FATCA</p>
      <span className="description">
        All fields with <RequiredFlag /> are mandatory
      </span>
      <FormRow>
        <label className="form-title">
          Are you a US Person? <RequiredFlag />
        </label>
        <span className="form-subtitle">
          A US Person includes a US Citizen or resident alien (e.g. US green cardholder) even if residing outside the US
        </span>
        <div className="controls --inline">
          <Radio.Group
            name="isUSPerson"
            value={accountholder.fatca.isUSPerson}
            onChange={e => accountholder.fatca.setIsUSPerson(e.target.value)}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </div>
        {errors['fatca.isUSPerson'] ? <span className="error-message">{errors['fatca.isUSPerson']}</span> : null}
        <div className={errors['fatca.tin'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Input
            name="tin"
            placeholder="US TIN"
            value={accountholder.fatca.tin}
            onChange={e => accountholder.fatca.setTin(e.target.value)}
            className="input"
            disabled={!accountholder.fatca.isUSPerson}
            onBlur={() => validateInput('fatca.tin')}
          />
          {accountholder.fatca.isUSPerson ? <RequiredFlag /> : null}
        </div>
        {errors['fatca.tin'] ? <span className="error-message">{errors['fatca.tin']}</span> : null}
      </FormRow>
    </FormSection>
  );
});

FATCA.propTypes = {
  errors: PropTypes.any.isRequired,
  accountholder: PropTypes.any.isRequired,
  validateInput: PropTypes.func.isRequired,
};

export default FATCA;
