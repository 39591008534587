import React, { useEffect, useState } from 'react';
import FileSaver from 'file-saver';
import { observer } from 'mobx-react';
import { Radio, Input, Select, Icon, Button, Tooltip, notification } from 'antd';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { RouteProps, useHistory, useLocation } from 'react-router-dom';
import { schema } from '@project/common';

import { FormRow, FormSection, RequiredFlag } from '../components';
import { useMst } from '../store/RootStore';
import { TradeAccountDetailsType } from '../types';
import { applySnapshot } from 'mobx-state-tree';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 80px;

  .btn-submit {
    width: 250px;
    height: 40px;
    margin-top: 30px;
    font-size: 20px;
    font-weight: bold;
  }

  .input {
    color: #333;

    &::placeholder {
      color: #888;
    }
  }

  .input-sm {
    width: 80px;
  }

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

const Main = styled.div`
  margin: auto;
  margin-bottom: 40px;
`;

const TooltipContent = styled.div`
  .title {
    font-size: 14px;
  }
  .list {
    margin-top: 10px;
    margin-left: 10px;
    padding-left: 20px;
  }
`;

const UserIdTooltipContent = () => (
  <TooltipContent>
    <span className="title">User ID must have the following</span>
    <ul className="list">
      <li>6-10 characters</li>
      <li>At least 1 letter</li>
      <li>At least 1 number</li>
    </ul>
  </TooltipContent>
);
const PasswordTooltipContent = () => (
  <TooltipContent>
    <span className="title">Password must have the following</span>
    <ul className="list">
      <li>7-20 characters</li>
      <li>At least 1 uppercase letter</li>
      <li>At least 1 lowercase letter</li>
      <li>At least 1 number</li>
      <li>At least 1 special character</li>
    </ul>
  </TooltipContent>
);

type FormData = {
  bpiTradeUserId: string;
  password: string;
  confirmPassword: string;
  securityQuestion: string;
  securityQuestionAnswer: string;
  bankAccountName: string;
  bankAccountNumber: string;
  hasAnExistingBPITradeAccount: boolean;
  accountCode: string;
  mailingMethod: string;
  preferredMailingAddress: string;
};

const TradeAccountDetails: React.FC<RouteProps> = observer(() => {
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const store = useMst();
  useEffect(() => {
    store.ui.setStep(2);
  }, []);

  const [userIdTooltipVisible, setUserIdTooltipVisible] = useState(false);
  const [passwordTooltipVisible, setPasswordTooltipVisible] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  const [mailingMethod, setMailingMethod] = useState<string | null>(null);
  const [hasBPIAccount, setHasBPIAccount] = useState<boolean | null>(null);

  const { errors, handleSubmit, control } = useForm<FormData>({
    validationSchema: schema.tradeAccountDetailsSchema,
  });

  const onSubmit = async (data: FormData) => {
    // console.log(data);

    // check username availability first
    setSubmitLoading(true);
    const response = await store.checkUsernameAvailability(data.bpiTradeUserId);
    // console.log(response);

    if (response.data.status === 'success') {
      store.setTradeAccountDetails(data as TradeAccountDetailsType);
      const registerResponse = await store.register();
      if (registerResponse) {
        const blob = new Blob([registerResponse.data], {
          type: 'application/pdf',
        });
        FileSaver.saveAs(blob, 'BPITrade_ApplicationForm.pdf');
        setSubmitLoading(false);
        history.push('/thank_you');
      } else {
        setSubmitLoading(false);
        notification['error']({
          message: 'Trade account details',
          description: `An error occurred. Please try again later.`,
        });

        await store.saveErrorResponse('error_register');
        return;
      }
    } else {
      setSubmitLoading(false);
      notification['error']({
        message: 'Trade account details',
        description: `UserID ${data.bpiTradeUserId} is already taken, please enter another id.`,
      });
    }
  };

  console.log(errors);

  return (
    <Wrapper>
      <Main>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormSection id="personal-section">
            <p className="title">Trade Account details</p>
            <span className="description">
              All fields with <RequiredFlag /> are mandatory
            </span>
            <FormRow>
              <label className="form-title">
                BPI Trade User ID
                <RequiredFlag />
              </label>
              <label className="form-subtitle">Nominate your BPI Trade User ID</label>
              <div className={errors['bpiTradeUserId'] ? 'controls --inline has-error' : 'controls --inline'}>
                <Controller
                  name="bpiTradeUserId"
                  as={<Input placeholder="BPI Trade User ID"></Input>}
                  control={control}
                  onFocus={() => {
                    if (window.innerWidth > 768) {
                      setUserIdTooltipVisible(true);
                    }
                  }}
                  onBlur={() => {
                    setUserIdTooltipVisible(false);
                  }}
                />
                <RequiredFlag />
                <Tooltip
                  placement="right"
                  visible={userIdTooltipVisible}
                  title={UserIdTooltipContent}
                  mouseLeaveDelay={0.1}
                >
                  <a
                    onMouseEnter={() => {
                      setUserIdTooltipVisible(true);
                    }}
                    onMouseLeave={() => setUserIdTooltipVisible(false)}
                  >
                    <Icon type="info-circle" theme="filled" style={{ fontSize: 18, marginLeft: 10, color: '#aaa' }} />
                  </a>
                </Tooltip>
              </div>
              {errors['bpiTradeUserId'] ? (
                <span className="error-message">{errors['bpiTradeUserId'].message}</span>
              ) : null}

              <div className={errors['password'] ? 'controls --inline has-error' : 'controls --inline'}>
                <Controller
                  name="password"
                  as={<Input.Password placeholder="Password"></Input.Password>}
                  control={control}
                  onFocus={() => {
                    if (window.innerWidth > 768) {
                      setPasswordTooltipVisible(true);
                    }
                  }}
                  onBlur={() => setPasswordTooltipVisible(false)}
                />
                <RequiredFlag />
                <Tooltip
                  placement="right"
                  visible={passwordTooltipVisible}
                  title={PasswordTooltipContent}
                  mouseLeaveDelay={0.1}
                >
                  <a
                    onMouseEnter={() => setPasswordTooltipVisible(true)}
                    onMouseLeave={() => setPasswordTooltipVisible(false)}
                  >
                    <Icon type="info-circle" theme="filled" style={{ fontSize: 18, marginLeft: 10, color: '#aaa' }} />
                  </a>
                </Tooltip>
              </div>
              {errors['password'] ? <span className="error-message">{errors['password'].message}</span> : null}

              <div className={errors['confirmPassword'] ? 'controls --inline has-error' : 'controls --inline'}>
                <Controller
                  name="confirmPassword"
                  as={<Input.Password placeholder="Confirm Password"></Input.Password>}
                  control={control}
                />
                <RequiredFlag />
              </div>
              {errors['confirmPassword'] ? (
                <span className="error-message">{errors['confirmPassword'].message}</span>
              ) : null}
            </FormRow>

            <FormRow>
              <label className="form-title">Security Question</label>
              <label className="form-subtitle">
                The security question will be used in case you forget your username and password.
              </label>
              <div className={errors['securityQuestion'] ? 'controls --inline has-error' : 'controls --inline'}>
                <Controller
                  name="securityQuestion"
                  as={
                    <Select placeholder="Security question">
                      <Select.Option value="mothers_maiden_name">{"What is your mother's maiden name?"}</Select.Option>
                    </Select>
                  }
                  control={control}
                />
                <RequiredFlag />
              </div>
              {errors['securityQuestion'] ? (
                <span className="error-message">{errors['securityQuestion'].message}</span>
              ) : null}

              <div className={errors['securityQuestionAnswer'] ? 'controls --inline has-error' : 'controls --inline'}>
                <Controller name="securityQuestionAnswer" as={<Input placeholder="Answer"></Input>} control={control} />
                <RequiredFlag />
              </div>
              {errors['securityQuestionAnswer'] ? (
                <span className="error-message">{errors['securityQuestionAnswer'].message}</span>
              ) : null}
            </FormRow>

            <FormRow>
              <label className="form-title">Bank Account Details</label>
              <div className={errors['bankAccountName'] ? 'controls --inline has-error' : 'controls --inline'}>
                <Controller name="bankAccountName" as={<Input placeholder="BPI Account Name" />} control={control} />
                <RequiredFlag />
              </div>
              {errors['bankAccountName'] ? (
                <span className="error-message">{errors['bankAccountName'].message}</span>
              ) : null}

              <div className={errors['bankAccountNumber'] ? 'controls --inline has-error' : 'controls --inline'}>
                <Controller
                  name="bankAccountNumber"
                  as={<Input placeholder="BPI Account Number"></Input>}
                  control={control}
                />
                <RequiredFlag />
              </div>
              {errors['bankAccountNumber'] ? (
                <span className="error-message">{errors['bankAccountNumber'].message}</span>
              ) : null}
            </FormRow>

            <FormRow>
              <label className="form-title">
                Existing BPI Trade Account Information
                <RequiredFlag />
              </label>
              <div className="controls --inline">
                <Controller
                  name="hasAnExistingBPITradeAccount"
                  onChange={data => {
                    setHasBPIAccount(data[0].target.value);
                    return data[0].target.value;
                  }}
                  as={
                    <Radio.Group>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  }
                  defaultValue={false}
                  control={control}
                />
              </div>

              <div className={errors['accountCode'] ? 'controls --inline has-error' : 'controls --inline'}>
                <Controller
                  name="accountCode"
                  as={<Input disabled={!hasBPIAccount} placeholder="Account code"></Input>}
                  control={control}
                />
                <RequiredFlag />
              </div>
              {errors['accountCode'] ? <span className="error-message">{errors['accountCode'].message}</span> : null}
            </FormRow>

            <FormRow>
              <label className="form-title">
                Mailing Instructions
                <RequiredFlag />
              </label>
              <label className="form-subtitle">
                This is where your account statements, confirmation advices, and other similar notices will be sent.
              </label>
              <div className="controls --inline">
                <Controller
                  name="mailingMethod"
                  onChange={data => {
                    setMailingMethod(data[0].target.value);
                    return data[0].target.value;
                  }}
                  as={
                    <Radio.Group>
                      <Radio value="email">Email</Radio>
                      <Radio value="courier">Courier</Radio>
                    </Radio.Group>
                  }
                  control={control}
                />
              </div>
              {errors['mailingMethod'] ? (
                <span className="error-message">{errors['mailingMethod'].message}</span>
              ) : null}

              <div className="controls --inline">
                <Controller
                  name="preferredMailingAddress"
                  as={
                    <Select disabled={mailingMethod !== 'courier'} placeholder="Preferred Mailing Address">
                      <Select.Option value="PERMANENT">Permanent Address</Select.Option>
                      <Select.Option value="PRESENT">Present Address</Select.Option>
                      <Select.Option value="OFFICE">Office Address</Select.Option>
                    </Select>
                  }
                  control={control}
                />
                <RequiredFlag />
              </div>
              {errors['preferredMailingAddress'] ? (
                <span className="error-message">{errors['preferredMailingAddress'].message}</span>
              ) : null}
            </FormRow>

            <FormRow style={{ marginTop: 30 }}>
              <Button loading={submitLoading} size="large" htmlType="submit" type="primary">
                Create account
              </Button>
            </FormRow>
          </FormSection>
        </form>
      </Main>
    </Wrapper>
  );
});

export default TradeAccountDetails;
