import { TradeAccountDetailsType } from './../types';
import { types, flow, Instance, onSnapshot, cast, getSnapshot } from 'mobx-state-tree';
import axios, { AxiosResponse } from 'axios';
import { createContext, useContext } from 'react';
import sampleData from '../data.json';

import { Profile, LoginResponse } from '../types';
import config from '../config';

import UI from './UI';
import TermsAndConditions from './TermsAndConditions';
import Accountholder, { createEmptyAccountholders } from './Accountholder';
import ApplicationDetails from './ApplicationDetails';
import TradeAccountDetails from './TradeAccountDetails';

const RootModel = types
  .model({
    id: types.string,
    termsAndConditions: TermsAndConditions,
    applicationDetails: ApplicationDetails,
    tradeAccountDetails: TradeAccountDetails,
    accountholders: types.array(Accountholder),
    ui: UI,
  })
  .actions(self => ({
    login: flow<AxiosResponse<LoginResponse>, any[]>(function*(): Generator<Promise<AxiosResponse<LoginResponse>>> {
      try {
        const response = yield axios.get(`${config.apiUrl}/auth/login`);
        return response;
      } catch (error) {
        console.log(error);
      }
    }),

    saveErrorResponse: flow<AxiosResponse<any>, any[]>(function*(
      reason: string,
    ): Generator<Promise<AxiosResponse<any>>> {
      try {
        const response = yield axios.post(`${config.apiUrl}/account/save_error_reason`, {
          applicationId: self.id,
          errorReason: reason,
        });
        return response;
      } catch (error) {
        console.log(error);
      }
    }),

    getProfile: flow<AxiosResponse<Profile>, any[]>(function*(cid: string): Generator<Promise<AxiosResponse<Profile>>> {
      try {
        const response = yield axios.get(`${config.apiUrl}/auth/profile?cid=${cid}`);
        return response;

        // return Promise.resolve({
        //   data: {
        //     firstName: 'FN1804651',
        //     middleName: 'MN1804651',
        //     lastName: 'LN1804651',
        //     suffix: '',
        //     gender: 'M',
        //     birthDate: '1980-02-05',
        //     birthPlace: 'MANILA',
        //     motherMaidenName: 'ANNA MARIA RODRIGUEZ',
        //     maritalStatus: 'M',
        //     spouseName: 'SP00000000804651',
        //     telephones: [
        //       {
        //         telType: 'M',
        //         telCountryCode: '+63',
        //         telAreaCode: '918',
        //         telNumber: '5222285',
        //         telNumberExtension: '',
        //       },
        //       {
        //         telType: 'R',
        //         telCountryCode: '+63',
        //         telAreaCode: '2',
        //         telNumber: '6327472',
        //         telNumberExtension: '',
        //       },
        //       {
        //         telType: 'R',
        //         telCountryCode: '+63',
        //         telAreaCode: '49',
        //         telNumber: '5412162',
        //         telNumberExtension: '',
        //       },
        //       {
        //         telType: 'R',
        //         telCountryCode: '+63',
        //         telAreaCode: '917',
        //         telNumber: '8675205',
        //         telNumberExtension: '',
        //       },
        //     ],
        //     addresses: [
        //       {
        //         addressType: 'HOME',
        //         addressLine1: 'AD1804651',
        //         addressLine2: '',
        //         addressLine3: '',
        //         addressLine4: '',
        //         cityProvince: 'AYALA-PASEO DE ROXAS',
        //         districtTown: 'MAKATI',
        //         country: '',
        //         zipCode: '026',
        //       },
        //       {
        //         addressType: 'PERMANENT',
        //         addressLine1: null,
        //         addressLine2: null,
        //         addressLine3: null,
        //         addressLine4: null,
        //         cityProvince: null,
        //         districtTown: null,
        //         country: null,
        //         zipCode: null,
        //       },
        //       {
        //         addressType: 'OFFICE',
        //         addressLine1: 'FEMAR GRAPHICS',
        //         addressLine2: '9F AYALA LIFE-FGU CENTER 6811 AYALA',
        //         addressLine3: null,
        //         addressLine4: null,
        //         cityProvince: 'AVENUE MAKATI CITY',
        //         districtTown: null,
        //         country: null,
        //         zipCode: null,
        //       },
        //     ],
        //     primaryEmail: '',
        //     citizenship: 'PH',
        //     taxIdentificationNumber: '210872268',
        //     sssOtherIDCode: '2',
        //     sssOtherID: '33863354202',
        //     employmentCode: 'EMP',
        //     employerName: 'FEMAR GRAPHICS',
        //     employerBusinessNature: 'OTH',
        //     sourceOfFunds: 'S',
        //     jobTitle: 'OTH',
        //     officeNumber: '',
        //   },
        // });
      } catch (error) {
        console.log(error);
        throw new Error('Profile does not exist');
      }
    }),

    setApplicationId(id: string) {
      self.id = id;
    },

    checkApplicationId: flow<AxiosResponse<{ data: boolean }>, any[]>(function*(): Generator<
      Promise<AxiosResponse<{ data: boolean }>>
    > {
      try {
        const response = yield axios.post(`${config.apiUrl}/auth/check_id`, { applicationId: self.id });
        return (response as AxiosResponse<{ data: boolean }>).data.data;
      } catch (error) {
        console.log(error);
        return false;
      }
    }),

    startRegistration: flow<AxiosResponse<any>, any[]>(function*(): Generator<Promise<AxiosResponse<any>>> {
      try {
        const response = yield axios.post(`${config.apiUrl}/account/start_registration`, { applicationId: self.id });
        return response;
      } catch (error) {
        console.log(error);
      }
    }),

    getTermsAndConditions: flow<AxiosResponse<any>, any[]>(function*(): Generator<Promise<AxiosResponse<any>>> {
      try {
        const response = yield axios.get(`${config.apiUrl}/account/terms`);
        return response;
      } catch (error) {
        console.log(error);
      }
    }),

    populateStoreFromBPIProfile(profile: Profile) {
      self.accountholders[0].personal.setFirstName(profile.firstName);
      self.accountholders[0].personal.setMiddleName(profile.middleName);
      self.accountholders[0].personal.setLastName(profile.lastName);
      self.accountholders[0].personal.setSuffix(profile.suffix.length ? profile.suffix : 'n/a');
      self.accountholders[0].personal.setGender(profile.gender === 'M' ? 'Male' : 'Female');
      self.accountholders[0].personal.setDateOfBirth(profile.birthDate);
      self.accountholders[0].personal.setBirthCity(profile.birthPlace);
      self.accountholders[0].personal.setMotherMaidenName(profile.motherMaidenName);
      // self.accountholders[0].personal.setCivilStatus(profile.maritalStatus);
      self.accountholders[0].personal.setSpouseName(profile.spouseName);
      self.accountholders[0].personal.setPrimaryEmailAddress(profile.primaryEmail);
      // self.accountholders[0].personal.setCitizenship(profile.citizenship.toLowerCase());
      self.accountholders[0].personal.setTin(profile.taxIdentificationNumber);
      self.accountholders[0].personal.setSSSGSISNo(profile.sssOtherID);
      self.accountholders[0].work.setPosition(profile.jobTitle);
      // self.accountholders[0].work.setEmploymentStatus(profile.jobTitle);
      self.accountholders[0].work.setNameOfEmployerOrBusiness(profile.employerName);
      // self.accountholders[0].work.setNatureOfBusiness(profile.employerBusinessNature);

      const tmpMobileNumber = profile.telephones.filter(tel => tel.telType === 'M');
      const tmpTelephoneNumber = profile.telephones.filter(tel => tel.telType === 'R');
      if (tmpMobileNumber.length) {
        const mobileNumber = tmpMobileNumber[0];
        const mNumber = `${mobileNumber.telCountryCode}${mobileNumber.telAreaCode}${mobileNumber.telNumber}`.replace(
          '+',
          '',
        );
        self.accountholders[0].personal.setMobileNumber(mNumber);
      }
      // if (tmpTelephoneNumber.length) {
      //   const telephoneNumber = tmpTelephoneNumber[0];
      //   self.accountholders[0].personal.setHomePhoneNumber(
      //     `${telephoneNumber.telCountryCode}${telephoneNumber.telAreaCode}${telephoneNumber.telNumber}`,
      //   );
      // }

      const homeAddresses = profile.addresses.filter(tel => tel.addressType === 'HOME');
      if (homeAddresses.length) {
        const homeAddress = homeAddresses[0];
        self.accountholders[0].personal.setPresentAddress1(homeAddress.addressLine1 ?? '');
        self.accountholders[0].personal.setPresentAddress2(homeAddress.addressLine2 ?? '');
        self.accountholders[0].personal.setPresentAddress3(homeAddress.addressLine3 ?? '');
        self.accountholders[0].personal.setPresentAddressZipCode(homeAddress.zipCode ?? '');
      }
      const permanentAddresses = profile.addresses.filter(tel => tel.addressType === 'PERMANENT');
      if (permanentAddresses.length) {
        const permanentAddress = permanentAddresses[0];
        self.accountholders[0].personal.setPermanentAddress1(permanentAddress.addressLine1 ?? '');
        self.accountholders[0].personal.setPermanentAddress2(permanentAddress.addressLine2 ?? '');
        self.accountholders[0].personal.setPermanentAddress3(permanentAddress.addressLine3 ?? '');
        self.accountholders[0].personal.setPermanentAddressZipCode(permanentAddress.zipCode ?? '');
      }
      const businessAddresses = profile.addresses.filter(tel => tel.addressType === 'OFFICE');
      if (businessAddresses.length) {
        const businessAddress = businessAddresses[0];
        self.accountholders[0].work.setBusinessAddress1(businessAddress.addressLine1 ?? '');
        self.accountholders[0].work.setBusinessAddress2(businessAddress.addressLine2 ?? '');
        self.accountholders[0].work.setBusinessAddress3(businessAddress.addressLine3 ?? '');
        self.accountholders[0].work.setBusinessAddressZipCode(businessAddress.zipCode ?? '');
      }
      self.accountholders[0].work.setOfficePhoneNumber(profile.officeNumber ?? '');
    },

    checkUsernameAvailability: flow<AxiosResponse<any>, any[]>(function*(
      username: string,
    ): Generator<Promise<AxiosResponse<any>>> {
      try {
        const response = yield axios.post(`${config.apiUrl}/account/check_userid_availability`, {
          userId: username,
        });
        return response;
      } catch (error) {
        console.log(error);
      }
    }),

    getApplicationNumber: flow<AxiosResponse<any>, any[]>(function*(): Generator<Promise<AxiosResponse<any>>> {
      try {
        const response = yield axios.post(`${config.apiUrl}/account/get_application_number`, {
          applicationId: self.id,
        });
        return response;
      } catch (error) {
        console.log(error);
      }
    }),

    register: flow<AxiosResponse<any>, any[]>(function*(): Generator<Promise<AxiosResponse<any>>> {
      try {
        const data = getSnapshot(self);
        const response = yield axios.post(`${config.apiUrl}/account/register`, data, {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
          },
        });
        return response;
      } catch (error) {
        console.log(error);
        return false;
      }
    }),

    setAccountholders(count: number) {
      if (count > 1) {
        self.accountholders = cast([self.accountholders[0], ...createEmptyAccountholders(count - 1)]);
      } else {
        self.accountholders = cast([self.accountholders[0]]);
      }
    },

    setTradeAccountDetails(data: TradeAccountDetailsType) {
      self.tradeAccountDetails = cast(data);
    },
  }));

export const rootStore = RootModel.create({
  id: '',
  termsAndConditions: TermsAndConditions.create(),
  tradeAccountDetails: TradeAccountDetails.create(),
  applicationDetails: ApplicationDetails.create(),
  accountholders: createEmptyAccountholders(1),
  ui: UI.create(),
});

// export const rootStore = RootModel.create(sampleData);

onSnapshot(rootStore, snapshot => {
  // console.log('Snapshot: ', snapshot);
  // console.log('Snapshot: ', JSON.stringify(snapshot));
});

export type RootInstance = Instance<typeof RootModel>;
const RootStoreContext = createContext<null | RootInstance>(null);

export const Provider = RootStoreContext.Provider;

export function useMst() {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null');
  }
  return store;
}
