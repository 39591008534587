import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { IoIosMail } from 'react-icons/io';
import { FaPhone } from 'react-icons/fa';

import { useMst } from '../store/RootStore';
import { AccountholderType } from '../store/Accountholder';
import config from '../config';

const Wrapper = styled.div`
  .title {
    font-size: 18px;
    margin-bottom: 30px;
  }
  p {
    margin-bottom: 30px;
  }
`;

const ThankYouPage: React.FC = () => {
  const store = useMst();

  const [applicationNumber, setApplicationNumber] = useState('');

  useEffect(() => {
    store.ui.setStep(3);

    store.getApplicationNumber().then(res => {
      setApplicationNumber(res.data.applicationNumber);
    });
  }, []);

  const USPeople = store.accountholders.filter((accountholder: AccountholderType) => {
    return accountholder.fatca.isUSPerson;
  });

  const USPeopleNames = USPeople.map(people => `${people.personal.firstName} ${people.personal.lastName}`).join(', ');

  return (
    <Wrapper>
      <h2 className="title">THANK YOU FOR APPLYING FOR A BPI TRADE ONLINE ACCOUNT</h2>
      <p>
        Your application number is: <b>{applicationNumber}</b>
      </p>
      <p>
        Kindly print and sign three times on the space provided on your accomplished Account Opening Form. Please submit
        the following through email or to any BPI Branch for signature verification:
      </p>
      <ul>
        <li>a. Printed and signed BPI Trade Account Opening Form</li>
        <li>b. Original and photocopy of one (1) valid ID</li>
        <li>c. W-8 BEN Form or W-9 Form - accomplish as needed for US Person and/or with US Indicia</li>
      </ul>
      <p>Instructions for submission:</p>
      <ul>
        <li>Send the signed Account Opening Form and a photo of your valid ID to bpitradeapplications@bpi.com.ph using the same email address stated on your application form OR</li>
        <li>Submit to any BPI branch for signature verification. Instruction for BPI receiving branch:</li>
      </ul>
      <ol>
        <li>Please ensure that client has signed the Account Opening Form and KYC is done.</li>
        <li>
          Branch officer/authorized personnel to append their name and signature on the 3rd page of the printed Account Opening Form from client.
        </li>
        <li>{`Stamp "Compared with Original" ALL presented valid IDs`}</li>
        <li>
          Send the requirements to our office address below:
          <br /> <br />
          BPI Securities Corporation <br />
          23rd Floor, Ayala Triangle Gardens Tower 2 <br />
          Paseo De Roxas Cor. <br />
          Makati Avenue, Makati City Philippines 1226
        </li>
      </ol>
      {/* 
      <div>
        <IoIosMail style={{ marginRight: 10 }} /> bpitradeapplications@bpi.com.ph{' '}
      </div>
      <div>
        <FaPhone style={{ marginRight: 10 }} />
        (02) 8246 5555
      </div> */}
    </Wrapper>
  );
};

export default ThankYouPage;
