import React from 'react';
import { observer } from 'mobx-react';
import { Input, Select } from 'antd';
import PropTypes from 'prop-types';
import { references } from '@project/common';

import { AccountholderType } from '../../store/Accountholder';
import { FormSection, FormRow, RequiredFlag } from '../../components';

interface ComponentProps {
  errors: any;
  accountholder: AccountholderType;
  validateInput: (path: string) => void;
}

const isNotEmployed = (s: string | undefined): boolean => {
  if (s) {
    return s === 'student' || s === 'retired' || s === 'unemployed' || s === 'housewife';
  }
  return false;
};

const WorkBusinessInformation: React.FC<ComponentProps> = observer(({ errors, accountholder, validateInput }) => {
  return (
    <FormSection id="work-section">
      <p className="title">Work / Business Information</p>
      <span className="description">
        All fields with <RequiredFlag /> are mandatory
      </span>
      <FormRow>
        <label className="form-title">Employment Status</label>
        <div className={errors['work.employmentStatus'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Select
            value={accountholder.work.employmentStatus}
            onBlur={(): any => validateInput('work.employmentStatus')}
            placeholder="Employment status"
            onChange={(value: string): void => {
              accountholder.work.setEmploymentStatus(value);
              validateInput('work.employmentStatusOthers');

              if (value === 'student' || value === 'housewife' || value === 'unemployed') {
                accountholder.disclosures.setIsOfficerOrDirectorOfListedCompany(false);
                accountholder.disclosures.setIsOfficerOrDirectorOfARegisteredBroker(false);
                accountholder.disclosures.setIsEmployeeOfRegisteredBroker(false);
              } else {
                accountholder.disclosures.setIsOfficerOrDirectorOfListedCompany(undefined);
                accountholder.disclosures.setIsOfficerOrDirectorOfARegisteredBroker(undefined);
                accountholder.disclosures.setIsEmployeeOfRegisteredBroker(undefined);
              }
            }}
          >
            {Object.keys(references.employmentStatuses).map(key => (
              <Select.Option key={key} value={key}>
                {references.employmentStatuses[key]}
              </Select.Option>
            ))}
          </Select>
          <RequiredFlag />
        </div>
        {errors['work.employmentStatus'] ? (
          <span className="error-message">{errors['work.employmentStatus']}</span>
        ) : null}

        <div className={errors['work.employmentStatusOthers'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Input
            name="employmentStatusOthers"
            value={accountholder.work.employmentStatusOthers}
            disabled={accountholder.work.employmentStatus !== 'others'}
            onChange={e => accountholder.work.setEmploymentStatusOthers(e.target.value)}
            className="input"
            placeholder="Employment Status (others)"
            onBlur={() => validateInput('work.employmentStatusOthers')}
          />
          {accountholder.work.employmentStatus === 'others' ? <RequiredFlag /> : null}
        </div>
        {errors['work.employmentStatusOthers'] ? (
          <span className="error-message">{errors['work.employmentStatusOthers']}</span>
        ) : null}
      </FormRow>
      <FormRow>
        <label className="form-title">Rank</label>
        <div className={errors['work.rank'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Select
            value={accountholder.work.rank}
            onBlur={() => validateInput('work.rank')}
            placeholder="Rank"
            disabled={isNotEmployed(accountholder.work.employmentStatus)}
            onChange={(value: string) => {
              accountholder.work.setRank(value);
              validateInput('work.rankOthers');
            }}
          >
            {Object.keys(references.ranks).map(key => (
              <Select.Option key={key} value={key}>
                {references.ranks[key]}
              </Select.Option>
            ))}
          </Select>
          <RequiredFlag />
        </div>
        {errors['work.rank'] ? <span className="error-message">{errors['work.rank']}</span> : null}

        <div className={errors['work.rankOthers'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Input
            name="rankOthers"
            value={accountholder.work.rankOthers}
            disabled={accountholder.work.rank !== 'others' || isNotEmployed(accountholder.work.employmentStatus)}
            onChange={e => accountholder.work.setRankOthers(e.target.value)}
            className="input"
            placeholder="Rank (others)"
            onBlur={() => validateInput('work.rankOthers')}
          />
          {accountholder.work.rank === 'others' ? <RequiredFlag /> : null}
        </div>
        {errors['work.rankOthers'] ? <span className="error-message">{errors['work.rankOthers']}</span> : null}

        <label className="form-title">Position</label>
        <div className={errors['work.position'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Input
            name="position"
            value={accountholder.work.position}
            disabled={isNotEmployed(accountholder.work.employmentStatus)}
            onChange={e => accountholder.work.setPosition(e.target.value)}
            className="input"
            placeholder="Position"
            onBlur={() => validateInput('work.position')}
          />
          <RequiredFlag />
        </div>
        {errors['work.position'] ? <span className="error-message">{errors['work.position']}</span> : null}

        <label className="form-title">Nature of Business</label>
        <div className={errors['work.natureOfBusiness'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Select
            showSearch
            value={accountholder.work.natureOfBusiness}
            disabled={isNotEmployed(accountholder.work.employmentStatus)}
            onBlur={() => validateInput('work.natureOfBusiness')}
            placeholder="Nature of business"
            onChange={(value: string) => {
              accountholder.work.setNatureOfBusiness(value);
              validateInput('work.natureOfBusinessOthers');
            }}
          >
            {Object.keys(references.natureOfBusiness).map(key => (
              <Select.Option key={key} value={key}>
                {references.natureOfBusiness[key]}
              </Select.Option>
            ))}
          </Select>
          <RequiredFlag />
        </div>
        {errors['work.natureOfBusiness'] ? (
          <span className="error-message">{errors['work.natureOfBusiness']}</span>
        ) : null}

        <div className={errors['work.natureOfBusinessOthers'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Input
            name="natureOfBusinessOthers"
            value={accountholder.work.natureOfBusinessOthers}
            disabled={
              accountholder.work.natureOfBusiness !== 'others' || isNotEmployed(accountholder.work.employmentStatus)
            }
            onChange={e => accountholder.work.setNatureOfBusinessOthers(e.target.value)}
            className="input"
            placeholder="Nature of Business (others)"
            onBlur={() => validateInput('work.natureOfBusinessOthers')}
          />
          {accountholder.work.natureOfBusiness === 'others' ? <RequiredFlag /> : null}
        </div>
        {errors['work.natureOfBusinessOthers'] ? (
          <span className="error-message">{errors['work.natureOfBusinessOthers']}</span>
        ) : null}

        <label className="form-title">Name of Employer or Business</label>
        <div className={errors['work.nameOfEmployerOrBusiness'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Input
            name="nameOfEmployerOrBusiness"
            value={accountholder.work.nameOfEmployerOrBusiness}
            disabled={isNotEmployed(accountholder.work.employmentStatus)}
            onChange={e => accountholder.work.setNameOfEmployerOrBusiness(e.target.value)}
            className="input"
            placeholder="Name of employer or business"
            onBlur={() => validateInput('work.nameOfEmployerOrBusiness')}
          />
          <RequiredFlag />
        </div>
        {errors['work.nameOfEmployerOrBusiness'] ? (
          <span className="error-message">{errors['work.nameOfEmployerOrBusiness']}</span>
        ) : null}
      </FormRow>

      <FormRow>
        <label className="form-title">Business address</label>
        <div className={errors['work.businessAddress1'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Input
            name="businessAddress1"
            value={accountholder.work.businessAddress1}
            disabled={isNotEmployed(accountholder.work.employmentStatus)}
            onChange={e => accountholder.work.setBusinessAddress1(e.target.value)}
            className="input"
            placeholder="Business address 1"
            onBlur={() => validateInput('work.businessAddress1')}
          />
          <RequiredFlag />
        </div>
        {errors['work.businessAddress1'] ? (
          <span className="error-message">{errors['work.businessAddress1']}</span>
        ) : null}

        <div className={errors['work.businessAddress2'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Input
            name="businessAddress2"
            value={accountholder.work.businessAddress2}
            disabled={isNotEmployed(accountholder.work.employmentStatus)}
            onChange={e => accountholder.work.setBusinessAddress2(e.target.value)}
            className="input"
            placeholder="Business address 2"
            onBlur={() => validateInput('work.businessAddress2')}
          />
          <RequiredFlag />
        </div>
        {errors['work.businessAddress2'] ? (
          <span className="error-message">{errors['work.businessAddress2']}</span>
        ) : null}

        <div className={errors['work.businessAddress3'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Input
            name="businessAddress3"
            value={accountholder.work.businessAddress3}
            disabled={isNotEmployed(accountholder.work.employmentStatus)}
            onChange={e => accountholder.work.setBusinessAddress3(e.target.value)}
            className="input"
            placeholder="Business address 3"
            onBlur={() => validateInput('work.businessAddress3')}
          />
          <RequiredFlag />
        </div>
        {errors['work.businessAddress3'] ? (
          <span className="error-message">{errors['work.businessAddress3']}</span>
        ) : null}

        <div className={errors['work.businessAddressZipCode'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Input
            name="businessAddressZipCode"
            value={accountholder.work.businessAddressZipCode}
            disabled={isNotEmployed(accountholder.work.employmentStatus)}
            onChange={e => accountholder.work.setBusinessAddressZipCode(e.target.value)}
            className="input"
            placeholder="Zip Code"
            onBlur={() => validateInput('work.businessAddressZipCode')}
          />
          <RequiredFlag />
        </div>
        {errors['work.businessAddressZipCode'] ? (
          <span className="error-message">{errors['work.businessAddressZipCode']}</span>
        ) : null}
      </FormRow>

      <FormRow>
        <div className={errors['work.officePhoneNumber'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Input
            name="officePhoneNumber"
            value={accountholder.work.officePhoneNumber}
            disabled={isNotEmployed(accountholder.work.employmentStatus)}
            onChange={e => accountholder.work.setOfficePhoneNumber(e.target.value)}
            className="input"
            placeholder="Office phone number"
            onBlur={() => validateInput('work.officePhoneNumber')}
          />
          <RequiredFlag />
        </div>
        {errors['work.officePhoneNumber'] ? (
          <span className="error-message">{errors['work.officePhoneNumber']}</span>
        ) : null}
      </FormRow>
    </FormSection>
  );
});

WorkBusinessInformation.propTypes = {
  errors: PropTypes.any.isRequired,
  accountholder: PropTypes.any.isRequired,
  validateInput: PropTypes.func.isRequired,
};

export default WorkBusinessInformation;
